<template>
  <div>
    <TheSellpageHeader />
    <TheSellpageBody />
    <Newsletter />
    <!-- <div v-for="text in text" :key="text.Key">
      <p>{{ text }}</p>
    </div> -->
  </div>
</template>

<script>
import Newsletter from "@/components/global/Newsletter";
export default {
  components: {
    Newsletter,
    TheSellpageHeader: () => import("@/components/sell/TheSellpageHeader.vue"),
    TheSellpageBody: () => import("@/components/sell/TheSellpageBody.vue")
  }
};
</script>
